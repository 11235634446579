import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import { socialMediaData, products, friendLinks } from "./data";

function App() {
  return (
    <div className="App mx-auto p-10 max-w-sm md:max-w-md lg:max-w-4xl">
      <Header />
      <AboutMe />
      <MyProduct />
      <SocialMedia />
      <MyFriend />
    </div>
  );
}

function Header() {
  return (
    <div className="h-30 flex justify-start mb-3">
      <a href="/">
        <div className="mr-2">关于我</div>
      </a>
    </div>
  );
}

function MyFriend() {
  return (
    <div className="mt-3">
      <Label name="我的朋友" />
      <div className="flex">
        {friendLinks.map((item) => (
          <FriendLink {...item} />
        ))}
      </div>
    </div>
  );
}

function MyProduct() {
  return (
    <div className="mt-3">
      <Label name="我的爱好" />
      <div className="flex flex-wrap">
        {products.map((item) => (
          <Product {...item} />
        ))}
      </div>
    </div>
  );
}

function AboutMe() {
  return (
    <div className="mt-3">
      <Label name="关于我" />
      <div className="text-left">
        <div>
          一个在深圳工作的湖北宜昌人，92 年出生，15
          年大学毕业，然后一直在互联网行业工作。
        </div>
        <div>
          {" "}
          作为一个码农，工作之余混迹{" "}
          <a href="https://github.com/hilaily" target="_blank">
            Github
          </a>
          ，是一个 gopher、vimer。
        </div>
        <div>
          除了写代码外，喜欢
          <a href="https://read.laily.net" target="_blank">
            看看书
          </a>
          ，现在也开始
          <a href="https://write.laily.net" target="_blank">
            练习写作
          </a>
          。
        </div>
        <div>以前爱看动漫，最近两年不知道怎么就看的少了。</div>
        <div>以前还爱打羽毛球和跑步，也荒废了很久了，得找机会再捡回来。</div>
        <div>
          喜欢
          <a
            href="https://zh.wikipedia.org/zh-hans/%E6%96%AF%E5%A4%9A%E8%91%9B%E4%B8%BB%E7%BE%A9"
            target="_blank"
          >
            斯多葛主义
          </a>
          ，希望能践行。
          <p>
            中文场景下一般用名字：周玄久，来自“我与我周旋久，宁作我”（《世说新语》）。英文场景下一般用名字
            Laily，这是一个自己造的词。
          </p>
        </div>
      </div>
    </div>
  );
}

function Contact() {
  return (
    <div className="mt-3">
      <div>
        <Label name="联系我" />
      </div>
      <div>
        <Avater link="" image="" />
      </div>
    </div>
  );
}

function SocialMedia() {
  return (
    <div>
      <div>
        <Label name="社交媒体" />
      </div>
      <div className="flex">
        {socialMediaData.map((item) => (
          <Avater image={item.image} link={item.link} />
        ))}
      </div>
    </div>
  );
}

function Product({
  pic,
  title,
  desc,
  link,
}: {
  pic: string;
  title: string;
  desc: string;
  link: string;
}) {
  return (
    <a href={link} target="_blank" className="flex mb-5 mr-5">
      <div className="w-28">
        <img src={pic} />
      </div>
      <div className="ml-3 w-36 text-left">
        <div className="text-base">{title}</div>
        <div className="text-gray-500 text-sm">{desc}</div>
      </div>
    </a>
  );
}

function FriendLink({
  name,
  link,
  iconLink,
}: {
  name: string;
  link: string;
  iconLink?: string;
}) {
  return (
    <div className="mr-3">
      <a href={link} target="_blank" className="hover:text-sky-500">
        {name}
      </a>
    </div>
  );
}

function Label({ name }: { name: string }) {
  return <div className="text-xl text-left my-3">{name}</div>;
}

function Avater({ link, image }: { link: string; image: string }) {
  return (
    <div className="m-2 w-16">
      <a href={link} target="_blank">
        <img src={image} />
      </a>
    </div>
  );
}

export default App;
