interface SocialMedia {
  link: string;
  image: string;
}

export const socialMediaData: SocialMedia[] = [
  {
    link: "https://github.com/hilaily",
    image: "/images/github-mark.png",
  },
];

interface Product {
  pic: string;
  title: string;
  desc: string;
  link: string;
}

export const products: Product[] = [
  {
    pic: "/images/blog.png",
    title: "博客(write.laily.net)",
    desc: "写字的地方，记录自己的想法",
    link: "https://write.laily.net",
  },
  {
    pic: "/images/note.png",
    title: "笔记(note.laily.net)",
    desc: "写笔记的地方，大部分都是技术相关",
    link: "https://note.laily.net",
  },
  {
    pic: "/images/reading-list.png",
    title: "书单(read.laily.net)",
    desc: "我的读书单",
    link: "https://read.laily.net",
  },
];

interface FriendLink {
  iconLink?: string;
  link: string;
  name: string;
}

export const friendLinks: FriendLink[] = [
  {
    name: "雨翔河",
    link: "https://yuxianghe.net",
  },
  {
    name: "独孤影",
    link: "https://www.duguying.net",
  },
  {
    name: "开源实验室",
    link: "https://www.kymjs.com",
  },
];
